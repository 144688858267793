import React from "react";
import styled from "styled-components";

import { Box, Badge, List } from "../../components/Core";

import imgB1 from "../../assets/image/jpeg/blog-details-img-1.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const AddVehicle = () => (
  <>
    {/* <!-- Blog section --> */}
    <Post>
      <div>
        <iframe
          width="730"
          height="412"
          src="https://www.youtube.com/embed/dWVEDzNhDTM"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div>
        <h3>Adding your 1st vehicle is as easy as adding your 100th.</h3>
        <p>You can add vehicles at any time by:</p>
        <List>
          <li>Log into your LoanerHub Account</li>
          <li>Click 'VEHICLES' on the left menu bar</li>
          <li>Then click the 'ADD VEHICLE' button.</li>
        </List>

        <p>
          We suggest filling out the "Add Vehicle" form by entering the VIN
          first. LoanerHub will decode the VIN and add in as much info for you
          as it can find.
        </p>
        <p>And as always...</p>
        <blockquote>
          With LoanerHub you can add all your vehicles without any extra fees.
        </blockquote>
      </div>
    </Post>
  </>
);

export default AddVehicle;
